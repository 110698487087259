exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-addons-matrix-index-tsx": () => import("./../../../src/pages/addons/matrix/index.tsx" /* webpackChunkName: "component---src-pages-addons-matrix-index-tsx" */),
  "component---src-pages-addons-matrix-search-tsx": () => import("./../../../src/pages/addons/matrix/search.tsx" /* webpackChunkName: "component---src-pages-addons-matrix-search-tsx" */),
  "component---src-pages-addons-matrix-top-authors-tsx": () => import("./../../../src/pages/addons/matrix/top-authors.tsx" /* webpackChunkName: "component---src-pages-addons-matrix-top-authors-tsx" */),
  "component---src-pages-addons-nexus-index-tsx": () => import("./../../../src/pages/addons/nexus/index.tsx" /* webpackChunkName: "component---src-pages-addons-nexus-index-tsx" */),
  "component---src-pages-addons-nexus-search-tsx": () => import("./../../../src/pages/addons/nexus/search.tsx" /* webpackChunkName: "component---src-pages-addons-nexus-search-tsx" */),
  "component---src-pages-addons-nexus-top-authors-tsx": () => import("./../../../src/pages/addons/nexus/top-authors.tsx" /* webpackChunkName: "component---src-pages-addons-nexus-top-authors-tsx" */),
  "component---src-pages-blog-search-tsx": () => import("./../../../src/pages/blog/search.tsx" /* webpackChunkName: "component---src-pages-blog-search-tsx" */),
  "component---src-pages-donate-by-bitpay-tsx": () => import("./../../../src/pages/donate/by-bitpay.tsx" /* webpackChunkName: "component---src-pages-donate-by-bitpay-tsx" */),
  "component---src-pages-donate-by-patreon-tsx": () => import("./../../../src/pages/donate/by-patreon.tsx" /* webpackChunkName: "component---src-pages-donate-by-patreon-tsx" */),
  "component---src-pages-donate-by-paypal-tsx": () => import("./../../../src/pages/donate/by-paypal.tsx" /* webpackChunkName: "component---src-pages-donate-by-paypal-tsx" */),
  "component---src-pages-donate-by-stripe-tsx": () => import("./../../../src/pages/donate/by-stripe.tsx" /* webpackChunkName: "component---src-pages-donate-by-stripe-tsx" */),
  "component---src-pages-donate-wall-tsx": () => import("./../../../src/pages/donate/wall.tsx" /* webpackChunkName: "component---src-pages-donate-wall-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-store-index-tsx": () => import("./../../../src/pages/store/index.tsx" /* webpackChunkName: "component---src-pages-store-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog-index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-distribution-tsx": () => import("./../../../src/templates/distribution.tsx" /* webpackChunkName: "component---src-templates-distribution-tsx" */),
  "component---src-templates-matrix-addon-tsx": () => import("./../../../src/templates/matrix/addon.tsx" /* webpackChunkName: "component---src-templates-matrix-addon-tsx" */),
  "component---src-templates-matrix-author-tsx": () => import("./../../../src/templates/matrix/author.tsx" /* webpackChunkName: "component---src-templates-matrix-author-tsx" */),
  "component---src-templates-matrix-category-tsx": () => import("./../../../src/templates/matrix/category.tsx" /* webpackChunkName: "component---src-templates-matrix-category-tsx" */),
  "component---src-templates-nexus-addon-tsx": () => import("./../../../src/templates/nexus/addon.tsx" /* webpackChunkName: "component---src-templates-nexus-addon-tsx" */),
  "component---src-templates-nexus-author-tsx": () => import("./../../../src/templates/nexus/author.tsx" /* webpackChunkName: "component---src-templates-nexus-author-tsx" */),
  "component---src-templates-nexus-category-tsx": () => import("./../../../src/templates/nexus/category.tsx" /* webpackChunkName: "component---src-templates-nexus-category-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

